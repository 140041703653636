@import 'styles/globals.scss';

$childrenHeight: 290px;

.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.children {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 50px);
  margin-top: 5vh;
  margin-left: 10vw; 
  margin-right: 10vw;  
}

