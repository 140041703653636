@import 'styles/globals';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 100px;
  padding: 10px;
}

.col {
  max-width: 50%;
}

.image {
  height: 360px;
  margin-left: 20px;
}

.rightCol {
  margin-left: 0px;
}

.para{
  color: $gray;
}