@import 'styles/globals';

.container {
  background-color: $white;
  max-height: 100vh !important;
}

:global(html), :global(body) {  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI';
  font-size: $fontSizeLarge;  
}

:global(.ant-alert-message), 
:global(.ant-form-item-label > label),
:global(.ant-form label) {
  font-size: $fontSizeLarge !important;
}