$borderColor: rgba(0, 0, 0, 0.06);
$gray: gray;
$black: black;
$blue: #1890FF;
$white: white;
$lightgray: lightgray;
$darkgray: #434343;
$backgroundColor: #fafafa;
$lightblue: #BAE7FF;
$antdBlue: #1890ff;
$yellow: #FAAD14;
$antdPurple: #722ED1;
$antdGreen: #52C41A;
$antdOrange: #fa8c16;
$silver: #cccccc;
$aliceBlue: #f0f8ff
