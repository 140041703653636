@import 'styles/globals.scss';

.handlebars {
  max-width: 1000px;

  .rectangle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $silver;
    padding: 10px 60px;
    margin-bottom: 20px;
    max-width: 1000px;
    background-color: $aliceBlue;
  }

  .rectangleLeftSection {
    display: flex;
    flex-direction: column;
    word-break: break-all;
  }

  &.is-mobile .rectangle {
    padding: 48px 16px 12px;
  }

  .hint {
    opacity: 0.5;
  }

  .copyLink,
  .viewMore {
    width: max-content;
  }

  .rectangleRightSection {
    img {
      max-height: 300px;
    }
  }
}
