@import 'styles/globals';

$spinnerContainerSize: 200px;

.container {
  display: flex;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $spinnerContainerSize;
  height: $spinnerContainerSize;
  background-color: $white;
  border: 1px solid $lightblue;
  flex-direction: column;
}

.text {
  margin-bottom: 10px;
}
.spinner {
}
