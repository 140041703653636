@import 'styles/globals';

.container {
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner{
  text-align: center;
}



