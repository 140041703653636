@import 'styles/globals';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 100px;
}

.col {
  max-width: 50%;
}

.image {
  height: 360px;
}

.rightCol {
  margin-left: 60px;
  h1 {
    font-size: 72px;
    color: $darkgray;
    font-weight: normal;
    margin-bottom: 24px;
  }

  h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
    margin-bottom: 16px;
  }
}
