.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  width: 100%;
  padding-top: 30px;
  font-weight: bold;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}

.submitButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.submitButton {
  width: 100px;
}